import React, { useState, useEffect} from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import axios from 'axios';
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl
} from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import { PUBLIC_URL } from '../../config';

const EditSession = () => {
    const navigate = useNavigate()
    const { course_id, session_id } = useParams();

    const [session, setSession] = useState({
        sessionNumber: '',
        assessments: []
      });
    
    const [readingMaterial, setReadingMaterial] = useState('');
    const [uploadedStatus, setUploadedStatus] = useState(false)

    useEffect(() => {
      const fetchCourse = async () => {
          try {
              const response = await axios.get(`${PUBLIC_URL}/api/courses/${course_id}/session/${session_id}`);
              let filename = response.data.materials[0];
              const file = await axios.get(`${PUBLIC_URL}/api/courses/files/${filename}`, {
                responseType: 'blob' 
              })
              setReadingMaterial(file.config.url);
              
              setSession({
                sessionNumber: response.data.session_number,
                assessments: response.data.assessments
              });
          } catch (error) {
              console.error("Error fetching courses:", error);
          }
      };
  
      fetchCourse();
  }, [course_id, session_id]);
  const handleSessionChange = (e) => {
    const { name, value } = e.target;
    setSession(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDocumentChange = (event) => {
    const file = event.target.files[0];
    setUploadedStatus(true)
    setReadingMaterial(file);
  };

  const handleAssessmentChange = (index, field, value) => {
    const newAssessments = session.assessments.map((assessment, idx) => {
      if (index === idx) {
        return { ...assessment, [field]: value };
      }
      return assessment;
    });
    setSession(prevState => ({
      ...prevState,
      assessments: newAssessments,
    }));
  };

  const handleOptionChange = (qIndex, oIndex, value) => {
    const newAssessments = session.assessments.map((assessment, idx) => {
      if (qIndex === idx) {
        const newOptions = assessment.options.map((option, oIdx) => {
          if (oIdx === oIndex) {
            return value;
          }
          return option;
        });
        return { ...assessment, options: newOptions };
      }
      return assessment;
    });
    setSession(prevState => ({
      ...prevState,
      assessments: newAssessments,
    }));
  };

  const handleAddQuestion = () => {
    setSession(prevState => ({
      ...prevState,
      assessments: [
        ...prevState.assessments,
        { question: '', options: ['', '', '', ''], correctAnswer: '' }
      ]
    }));
  };

  const handleRemoveQuestion = (index) => {
    setSession(prevState => ({
      ...prevState,
      assessments: prevState.assessments.filter((_, idx) => idx !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('sessionNumber', session.sessionNumber);
    formData.append('readingMaterial', readingMaterial);
    formData.append('assessments', JSON.stringify(session.assessments));

    let token = localStorage.getItem('token')
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/courses/${course_id}/session/${session_id}/edit`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      alert('Session updated successfully');
      navigate(`/course-content-details/${course_id}`)
    } catch (error) {
      console.error(error);
      alert('Failed to update session');
    }
  };

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <h1>Edit Session</h1>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                name="sessionNumber"
                label="Session Number"
                fullWidth
                variant="outlined"
                value={session.sessionNumber}
                onChange={handleSessionChange}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept=".pdf,.doc,.docx"
                style={{ display: 'none' }}
                id="course-document"
                type="file"
                onChange={handleDocumentChange}
              />
              <label htmlFor="course-document">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{ backgroundColor: '#0147AB' }}
                >
                  Upload Reading Material
                </Button>
              </label>
              {readingMaterial && (
                <Box mt={2} display="flex" alignItems="center">
                  <Typography variant="body2" style={{ marginRight: '10px' }}>
                    {readingMaterial.name}
                  </Typography>
                  {uploadedStatus ?
                  (<a
                    href={URL.createObjectURL(readingMaterial)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0147AB' }}
                  >
                    View Uploaded Document
                  </a>) : (<a
                    href={readingMaterial}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: '#0147AB' }}
                  >
                    View Existing Document
                  </a>)}
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Assessments</Typography>
              {session.assessments.map((assessment, qIndex) => (
                <Box key={qIndex} mb={2} p={2} border={1} borderColor="grey.300">
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label={`Question ${qIndex + 1}`}
                        fullWidth
                        variant="outlined"
                        value={assessment.question}
                        onChange={(e) =>
                          handleAssessmentChange(qIndex, 'question', e.target.value)
                        }
                        required
                      />
                    </Grid>
                    {assessment.options.map((option, oIndex) => (
                      <Grid item xs={6} sm={3} key={oIndex}>
                        <TextField
                          label={`Option ${oIndex + 1}`}
                          fullWidth
                          variant="outlined"
                          value={option}
                          onChange={(e) =>
                            handleOptionChange(qIndex, oIndex, e.target.value)
                          }
                          required
                        />
                      </Grid>
                    ))}
                    <Grid item xs={12}>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel id={`correct-answer-label-${qIndex}`}>Correct Answer</InputLabel>
                        <Select
                          labelId={`correct-answer-label-${qIndex}`}
                          label="Correct Answer"
                          value={assessment.correctAnswer}
                          onChange={(e) =>
                            handleAssessmentChange(qIndex, 'correctAnswer', e.target.value)
                          }
                          required
                        >
                          {assessment.options.map((option, oIndex) => (
                            <MenuItem key={oIndex} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <IconButton
                        color="secondary"
                        onClick={() => handleRemoveQuestion(qIndex)}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddQuestion}
                startIcon={<AddCircleOutline />}
                sx={{ backgroundColor: '#0147AB' }}
              >
                Add Question
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" sx={{ backgroundColor: '#0147AB' }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default EditSession;
