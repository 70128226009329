import React, { useEffect, useState} from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { PUBLIC_URL } from '../config';
import { useParams } from 'react-router-dom';
import UserStatusBadge from './UserStatusBadge';

const UserReport = () => {
    const { id } = useParams();
    const [reportData, setReportData] = useState({
        courseTitle: '',
        users: [],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(()=> {
        const fetchReport = async() => {
            setLoading(true);
            setError(null);
    
            try {
                const response = await axios.get(`${PUBLIC_URL}/api/courses/${id}/getReport`);
                const { courseTitle, users } = response.data;
                setReportData({ courseTitle, users });
            } catch (error) {
                setError('Error fetching report');
                console.error('Error fetching report:', error);
            } finally {
                setLoading(false);
            }
        }
        fetchReport()
    }, [id])

    const downloadReportAsExcel = (data) => {
        // Create a new workbook
        const workbook = XLSX.utils.book_new();
    
        // Convert each section to a worksheet and append to the workbook
        const worksheetData = data['users'].map((item, index) => {
                    return {
                        'S.No.': index + 1,
                        ...item
                    };
                });
    
        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    
        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(workbook, worksheet);
        
        const reportName = data["courseTitle"] + "_report.xlsx"
        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, reportName);
    };

    return (
        <div className="container">
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className='course-details course'>
                    {error && <p>{error}</p>}
                    <h1 style={{margin:"0 0 1.5rem 0"}}>User Report</h1>
                    <h2>Course Name: {reportData["courseTitle"]}</h2>
                    <div>
                        <table>
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Gender</th>
                                        <th>Country</th>
                                        <th>Qualification</th>
                                        <th>Profession</th>
                                        <th>Status</th>
                                        <th>Current Session</th>
                                        <th>Last Attempt Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {reportData.users.map((data, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.gender}</td>
                                            <td>{data.country}</td>
                                            <td>{data.qualification}</td>
                                            <td>{data.profession}</td>
                                            <td><UserStatusBadge status={data.status}/></td>
                                            <td>{data.currentSession}</td>
                                            <td>{data.lastAttemptDate}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                    </div>
                    <hr/>
                    <button onClick={() => downloadReportAsExcel(reportData)} className='course-button'>
                        Download Report as Excel
                    </button>
                </div>
            )}
        </div>
    );
};

export default UserReport;
