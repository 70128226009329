import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import { AddCircleOutline} from '@mui/icons-material';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_URL } from '../../config';
const AddCourseDetails = () => {
    const navigate = useNavigate()
    const [courseTitle, setCourseTitle] = useState('');
    const [courseImage, setCourseImage] = useState('');
    const courseMetadata = {
          courseCode: '',
          credit: '',
          semester: '',
          teachingHours: '',
          courseNature: '',
      }
    const [courseIntroduction, setCourseIntroduction] = useState('');
    const [enrollmentEligibility, setEnrollmentEligibility] = useState('');
    const [courseOutcomes, setCourseOutcomes] = useState(['']);
    const [courseContents, setCourseContents] = useState(['']);
    const [assessment, setAssessment] = useState('');

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCourseImage(e.target.files[0]);
    }
  };

  const handleAddOutcome = () => {
    setCourseOutcomes([...courseOutcomes, '']);
  };

  const handlePopOutcome = () => {
    setCourseOutcomes(courseOutcomes.slice(0, -1));
  };

  const handleOutcomeChange = (index, value) => {
    const newOutcomes = [...courseOutcomes];
    newOutcomes[index] = value;
    setCourseOutcomes(newOutcomes);
  };

  const handleAddContent = () => {
    setCourseContents([...courseContents, '']);
  };

  const handlePopContent = () => {
    setCourseContents(courseContents.slice(0, -1));
  };

  const handleContentChange = (index, value) => {
    const newContents = [...courseContents];
    newContents[index] = value;
    setCourseContents(newContents);
  };

  // const handleMetadataChange = (event) => {
  //   const { name, value } = event.target;
  //   setCourseMetadata(prevState => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  
  const handleSubmit = async (e) => {
    console.log("helloo")
    e.preventDefault();
    // Database ma data pathaune
    try {
      const formData = new FormData();
      formData.append('title', courseTitle);
      formData.append('introduction', courseIntroduction);
      formData.append('enrollment_eligibility', enrollmentEligibility);
      const updatedOutcomes = courseOutcomes.map(outcome => {
        if (!outcome.endsWith(';')) {
          return outcome + ';';
        }
        return outcome;
      });
      formData.append('course_outcomes', updatedOutcomes);
      const updatedContents = courseContents.map(content => {
        if (!content.endsWith(';')) {
          return content + ';';
        }
        return content;
      });
      formData.append('course_contents', updatedContents);
      formData.append('assessment', assessment);
      formData.append('course_image', courseImage);
      formData.append('course_metadata', JSON.stringify(courseMetadata));
      console.log(courseImage);
      const token = localStorage.getItem('token');

      console.log(token);
      await axios.post(`${PUBLIC_URL}/api/courses/add`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      }); 
      alert('Course introduction updated successfully');
      navigate('/')
    } catch (error) {
      console.log(error)
      alert("Failed to add course introduction. Ensure all sections are filled, including course image.");
    }
    
  };

  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <h1>Add New Course Details</h1>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Course Title"
                fullWidth
                style={{marginTop: "5px"}}
                variant="outlined"
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="course-image"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="course-image">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{ backgroundColor: '#0147AB' }}
                >
                  Upload Course Image
                </Button>
              </label>
              {courseImage && (
                <Box mt={2} display="flex" alignItems="center">
                  <img
                    src={courseImage}
                    alt="Course"
                    style={{ width: '100px', height: '100px', marginRight: '10px' }}
                  />
                  <Typography variant="body2">{courseImage.name}</Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Course Introduction"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={courseIntroduction}
                onChange={(e) => setCourseIntroduction(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enrollment Eligibility"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={enrollmentEligibility}
                onChange={(e) => setEnrollmentEligibility(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: '20px' }}>Course Outcomes</Typography>
              {courseOutcomes.map((outcome, index) => (
                <TextField
                  key={index}
                  label={`Outcome ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  value={outcome}
                  onChange={(e) => handleOutcomeChange(index, e.target.value)}
                  style={{ marginBottom: '8px' }}
                />
              ))}
              <IconButton color="primary" onClick={handleAddOutcome}>
                <AddCircleOutline />
              </IconButton>
              <IconButton color="primary" onClick={handlePopOutcome}>
                <RemoveCircleOutlineSharpIcon/>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: '20px' }}>Course Contents</Typography>
              {courseContents.map((content, index) => (
                <TextField
                  key={index}
                  label={`Content ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  value={content}
                  onChange={(e) => handleContentChange(index, e.target.value)}
                  style={{ marginBottom: '8px' }}
                />
              ))}
              <IconButton color="primary" onClick={handleAddContent}>
                <AddCircleOutline />
              </IconButton>
              <IconButton color="primary" onClick={handlePopContent}>
                <RemoveCircleOutlineSharpIcon/>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Assessment"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={assessment}
                onChange={(e) => setAssessment(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" sx={{ backgroundColor: '#0147AB' }}>
                Add
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default AddCourseDetails;
