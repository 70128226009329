// src/components/Navbar.js
import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { UserContext } from '../UserContext';

const Navbar = () => {
  const { user, setUser } = useContext(UserContext)
  const navigate = useNavigate();

  function logout() {
    localStorage.removeItem('token');
    setUser(null);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <nav className="navbar">
      <div class="logo-container">
            <img src="/assets/images/Tribhuvan_University_logo.svg" alt="TU Logo"/>
            <img src="/assets/images/nou_logo.png" alt="NOU Logo"/>
            <img src="/assets/images/HAMK_logo.png" alt="HAMK Logo" style={{height:"30px"}}/>
            <img src="/assets/images/jamk.png" alt="JAMK Logo"/>
            <img src="/assets/images/MFA-finland.png" alt="MFA Finland Logo"/>
        </div>
        <div class="auth-buttons">
            {user ? (
              <div className="navbar-user-info">
                {user.role === 'admin' ? 'Admin' : 'User'}: {user.username}
                <button onClick={handleLogout} className="card-button">Log out</button>
              </div>
            ) : (
              <>
              <Link to="/login" className="login card-button" style={{textDecoration: "none", fontSize:"0.9rem"}}>Login</Link>
              <Link to="/register" className="create-account card-button" style={{textDecoration: "none", fontSize:"0.9rem"}}>Create Account</Link>
              </>
            )}
        </div>
      {/* <div className="navbar-left">
        <Link to="/" className="navbar-logo">
          <img src="/assets/images/logo.jpeg" alt="Logo" />
        </Link>
        <Link to="/" className="navbar-home">Home</Link>
      </div>
      <div className="navbar-right">
        {user ? (
          <div className="navbar-user-info">
            {user.role === 'admin' ? 'Admin' : 'User'}: {user.username}
            <button onClick={handleLogout} className="card-button">Log out</button>
          </div>
        ) : (
          <Link to="/login" className="card-button" style={{textDecoration: "none", marginRight:"2rem", fontSize:"0.9rem"}}>Login</Link>
        )}
      </div> */}
    </nav>
  );
};

export default Navbar;
