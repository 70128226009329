// src/components/Assessment.js
import React, { useContext, useState } from 'react';
import './MainStyles.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { UserContext } from './UserContext';
import { useParams } from 'react-router-dom';
import { PDFViewer } from '@react-pdf/renderer';
import MyPDF from './MyPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';
import axios from 'axios';
import { PUBLIC_URL } from '../config';

const Assessment = ({ session, onComplete, completed, readingMaterial, isLastSession, courseTitle}) => {
  const { user } = useContext(UserContext)
  const fullName = user.firstname + " " + user.lastname
  const button_options = ['a', 'b', 'c', 'd']
  const [answers, setAnswers] = useState({});
  const [score, setScore] = useState(null);
  const [attempted, setAttempted] = useState(false)
  const [showCertificate, setShowCertificate] = useState(false)
  const [passed, setPassed] = useState(false)
  const [attemptsRemaining, setAttemptsRemaining] = useState()
  const [correctAnswers, setCorrectAnswers] = useState({})
  const [loading, setLoading] = useState(false); 

  const { id } = useParams()
  const certificate_text = isLastSession?"Completion":"Participation"

  const handleAnswerChange = (questionIndex, answer) => {
    setAnswers({ ...answers, [questionIndex]: answer });
  };

  const handleTryAgain = () => {
    onComplete(session._id)
    setAttempted(false)
    setScore(null)
    setAnswers({})
  }

  const allQuestionsAnswered = () => {
    return session.assessments.every((_, index) => answers[index] !== undefined);
  };

  const handleSubmitAssessment = async () => {
    if (!allQuestionsAnswered()) {
      alert('Please answer all questions before submitting.');
      return;
    }
    setLoading(true)
    try {
      let token = localStorage.getItem('token')
      let response = await axios.post(`${PUBLIC_URL}/api/courses/${id}/session/${session._id}/assessment/submit`,
        {
          answers: answers,
          userId: user._id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      setAttempted(true)
      setScore(response.data.score)
      setAttemptsRemaining(response.data.attemptsRemaining)
      onComplete(session._id)
      setCorrectAnswers(response.data.correctAnswers)
      if (response.data.passed) {
        setPassed(true)
      } else {
        setAnswers({})
      }
    } catch (error) {
        alert("Issue submitting assessment")
    } finally {
      setLoading(false);
    }
   
  };


  return (
    <div className="course-details course assessment">
      <h2>Assessment</h2>
      {!attempted &&
        <div>
          <p className='assessment-subtitle'> <b>A minimum score of 60%</b> is required in order to be qualified for the certification. After that, you can move on to the next session. If you are not able to score it, you will have the next <b>two chances to try again.</b>
            </p>
          {session.assessments !== undefined && session.assessments.map((questions, index) => (

            <div key={index} className="question">
              <strong><p dangerouslySetInnerHTML={{ __html: ` ${index + 1}. ${questions.question}` }} /></strong>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group"
              >
                {questions.options.map((option, i) => (
                  <div className='radio-class'>
                    <FormControlLabel value={option} control={<Radio />} label={button_options[i] + ". " + option} onChange={() => handleAnswerChange(index, option)} />
                  </div>
                ))}
              </RadioGroup>
              <hr />
            </div>
          ))}
          <button className='course-button assessment-button' onClick={handleSubmitAssessment} disabled={!allQuestionsAnswered() || user.role === "admin" || loading}>{loading ? 'Submitting...' : 'Submit Assessment'}</button>
          {user.role === "admin" && <p style={{ color: 'red' }}>Note: Admins cannot submit this assessment.</p>}
        </div>
      }

      {score !== null && (
        <div>
          {passed ? (
            <div>
              <h3>Correct Answers:</h3>
              {session.assessments.map((question, index) => (
                <div key={index} className="question">
                  <p dangerouslySetInnerHTML={{ __html: question.question }} />
                  <p><strong>Correct Answer:</strong> {correctAnswers[index]}</p>
                  <p><strong>Your Answer:</strong> {answers[index]}</p>
                  <hr />
                </div>
              ))}
              <h2 className={`score ${score >= 60 ? 'pass' : 'fail'}`}>Your score: {score}%</h2>
              {isLastSession?<p className="next-session-message score pass">Congratulations! You have finished the course!</p>:
              <p className="next-session-message score pass">Congratulations! You have unlocked the next session. You can move on to it now.</p>}
            
              {completed &&
                (
                  <div className='score pass'>
                    <PDFDownloadLink document={<MyPDF fullName={fullName} isComplete={isLastSession} courseTitle={courseTitle}/>} fileName="participation_certificate.pdf">
                      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button className='course-button'>Download {certificate_text} Certificate</button>)}
                    </PDFDownloadLink>

                    {!showCertificate &&
                      <button className='course-button' onClick={() => setShowCertificate(true)}>View {certificate_text} Certificate</button>}
                    {showCertificate &&
                      <button className='course-button' onClick={() => setShowCertificate(false)}>Hide {certificate_text} Certificate</button>}
                    {showCertificate &&
                      <PDFViewer style={{ width: '100%', height: '650px' }}><MyPDF fullName={fullName} isComplete={isLastSession} courseTitle={courseTitle}/></PDFViewer>}
                  </div>
                )}
            </div>
          ) : (
            <>
              <h2 className={`score ${score >= 60 ? 'pass' : 'fail'}`}>Your score: {score}%</h2>
              <div className='score fail'>
                <p>You should get more than 60% to proceed.</p>
                {attemptsRemaining === 0 ? <p>You have exhausted all your attempts. Please try again after 90 days.</p> :
                  <><p>You have only {attemptsRemaining} attempts remaining.</p>
                    <button className='course-button' onClick={handleTryAgain}>Try Again</button>
                    <a href={readingMaterial} rel="noreferrer" target='_blank'>
                      <button className="course-button">Review Reading Materials</button>
                    </a>
                  </>
                }

              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Assessment;
