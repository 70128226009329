import React, { useState, useContext, useEffect } from 'react';
import Session from './Sessions';
import { useParams, Link } from 'react-router-dom';
import { UserContext } from './UserContext';
import './MainStyles.css';
import axios from 'axios';
import { PUBLIC_URL } from '../config';
import MyPDF from './MyPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';

const Course = () => {
  const { user } = useContext(UserContext);
  const fullName = user.firstname + " " + user.lastname
  const [completedSessions, setCompletedSessions] = useState([]);
  const [expandedSession, setExpandedSession] = useState(null);
  const [course, setCourse] = useState([]);
  const [userCourseTrack, setUserCourseTrack] = useState({});
  const [isCourseLocked, setIsCourseLocked] = useState(false);
  const [sessionsPassedStatus, setSessionsPassedStatus] = useState([])

  const { id } = useParams();

  useEffect(() => {
    const fetchCourse = async () => {
      try {
        const response = await axios.get(`${PUBLIC_URL}/api/courses/${id}`);
        setCourse(response.data);
      
        if (user.role !== "admin") {
          const userCourseTrackResponse = await axios.get(`${PUBLIC_URL}/api/courses/${id}/sessions/${user._id}`);
          const userCourseTrackData = userCourseTrackResponse.data;
          setUserCourseTrack(userCourseTrackData);

          const passedArray = userCourseTrack.sessionAttempts.map(attempt => attempt.passed);
          setSessionsPassedStatus(passedArray)

          const currentDate = new Date();
          const lockedUntilDate = new Date(userCourseTrackData.courseLockedUntil);
          if (currentDate < lockedUntilDate) {
            setIsCourseLocked(true);
          }
        }
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourse();
  }, [completedSessions,id, user._id, user.role, userCourseTrack.sessionAttempts]);

  const handleSessionComplete = (sessionId) => {
    setCompletedSessions([...completedSessions, sessionId]);
  };

  const canAccessSession = (sessionNumber) => {
    const session = userCourseTrack.sessionAttempts?.find(s => s.session_number === sessionNumber.toString());
    return session ? session.unlocked : false;
  };

  const isCourseCompleted = () => {
    return userCourseTrack.completed
  };

  const toggleSession = (sessionId) => {
    setExpandedSession(expandedSession === sessionId ? null : sessionId);
  };

  return (
    <div className="course-details course">
      {isCourseLocked ? (
        <div className="course-locked-overlay">
          <div className="course-locked-message">
            <p>The course is locked until {new Date(userCourseTrack.courseLockedUntil).toLocaleDateString()}.</p>
            <p>Please come back after this date.</p>
          </div>
        </div>
      ):(
        <div className={`course-content`}>
        <div>
          <Link to="/" className="top-link">
            Faculty of Social Sciences and Education
          </Link>
          <span> {'>'} </span>
          <Link to={`/course-details/${course._id}`} className="top-link">
            {course.title}
          </Link>
          <span> {'>'} </span>
          <Link to={`/course-content-details/${course._id}`} className="top-link active">
            Sessions
          </Link>
        </div>

        <h1>{course.title}: Course Sessions</h1>
        {userCourseTrack.completed === true && user.role === "user" &&
          <PDFDownloadLink document={<MyPDF fullName={fullName} isComplete={true} courseTitle={course.title}/>} fileName="completion_certificate.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button className='course-button' style={{marginBottom:"2rem"}}>Download Completion Certificate</button>)}
          </PDFDownloadLink>
        }
        {course.sessions !== undefined && course.sessions.map((session, index) => (
          <div key={session.id}>
            <div className="session-header" onClick={() => toggleSession(session._id)}>
              <h3>Session {session.session_number}</h3>
              {sessionsPassedStatus[index] && <p className='session-badge'>Completed</p>}
              <button className="expand-collapse-button">
                {expandedSession === session._id ? '-' : '+'}
              </button>
            </div>
            {expandedSession === session._id && (
              <div className="session-content">
                {(user.role === "admin" || canAccessSession(session.session_number)) ? (
                  <Session session={session} handleSessionComplete={handleSessionComplete} completed={isCourseCompleted} passedStatus={sessionsPassedStatus[index]} isLastSession={index === course.sessions.length - 1} courseTitle={course.title}/>
                ) : (
                  <div className="locked-session">
                    <p>This session is locked. Complete the previous session to unlock.</p>
                  </div>
                )}
              </div>
            )}
          </div>
        ))}
        <Link to={`/add-session/${course._id}`}>
          {user.role === "admin" && <button className="course-button session-button">Add New Session</button>}
        </Link>
      </div>
      )}
      </div>
  );

}


export default Course;
