import React from 'react';
import './MainStyles.css';

const UserStatusBadge = ({ status }) => {
  let badgeClass = '';
  let badgeText = '';

  console.log(status)
  switch (status) {
    case 'In progress':
      badgeClass = 'user-badge user-badge-in-progress';
      badgeText = 'InProgress';
      break;
    case 'Completed':
      badgeClass = 'user-badge user-badge-completed';
      badgeText = 'Completed';
      break;
    case 'Locked':
      badgeClass = 'user-badge user-badge-locked';
      badgeText = 'Locked';
      break;
    default:
      badgeClass = 'user-badge';
      badgeText = status;
  }

  return <span className={badgeClass}>{badgeText}</span>;
};

export default UserStatusBadge;
