// src/components/Session.js
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import './MainStyles.css';
import {Link, useNavigate, useParams} from 'react-router-dom';
import Assessment from './Assessment';
import { UserContext } from './UserContext';
import { PUBLIC_URL } from '../config';
import MyPDF from './MyPDF';
import { PDFDownloadLink } from '@react-pdf/renderer';


const Session = ({ session, handleSessionComplete, completed, passedStatus, isLastSession, courseTitle }) => {
  const navigate = useNavigate()
  const {user} = useContext(UserContext)
  const fullName = user.firstname + " " + user.lastname
  const { id } = useParams();

  const [showAssessment, setShowAssessment] = useState(false);
  const [isRead, setIsRead] = useState(false);
  const certificate_text = isLastSession?"Completion":"Participation"

  const [readingMaterial, setReadingMaterial] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleMarkAsRead = () => {
    setIsRead(!isRead);
  };

  const handleDelete = async (e) =>{
    const confirmDelete = window.confirm("Are you sure you want to delete this session?");

    if (confirmDelete){
        try {
            const token = localStorage.getItem('token');
            const response = await axios.delete(`${PUBLIC_URL}/api/courses/${id}/delete/${session._id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }})
            if (response.status === 200) {
            alert('Session deleted successfully');
            navigate('/')
            }
        } catch (error) {
            console.error('Error deleting session:', error);
            alert('Failed to delete the session');
            }
        }
    }

  useEffect(() => {
    const fetchCourse = async () => {
        try {
            const file = await axios.get(`${PUBLIC_URL}/api/courses/files/${session.materials[0]}`, {
              responseType: 'blob' 
            })
            setReadingMaterial(file.config.url);
        } catch (error) {
            console.error("Error fetching reading material:", error);
        } finally {
            setIsLoading(false)
        }
    };

    if (session.materials && session.materials[0]) {
      fetchCourse();
    }
}, [session.materials]);


  return (
    <div>
      <Link to={`/edit-session/${id}/${session._id}`}>
            {user.role === "admin" && <button className="course-button">Edit Session</button>}
          </Link>
          {user.role === "admin" &&
      <button className="course-button delete" onClick={() => handleDelete(session._id)}>
       Delete Session
      </button>}
      {!showAssessment && (
        <>
          <p>You have to first click on the <b>Click Here to Read</b> button to read the session materials. After you have read it, you should click on <b>Mark as Read</b>. Only after that you can take the assessment.</p>
          <div className='session-structure'>
            <div className='session-cards'>
              <div className="card session-cards">
                  <div>
                    <h3 className="sessioncard-title">Reading Materials</h3>
                    <a href={readingMaterial} target="_blank" rel="noreferrer">
                      <button className="card-button" disabled={isLoading}>{isLoading? "Loading...":"Click Here to Read"}</button>
                    </a>
                    <button
                      onClick={handleMarkAsRead}
                      className={`mark-as-read-button ${isRead || passedStatus ? 'read' : ''}`}
                      disabled={isLoading}
                    >
                      {isRead || passedStatus ? '✓ Read' : 'Mark as Read'}
                    </button>
                </div>
                <img src="/assets/images/reading.png" alt="reading icon" className="sessioncard-image" />
              </div>
              <div className="card session-cards">
                <div>
                  <h3 className="sessioncard-title">Session Assessment</h3>
                    <button
                        onClick={() => setShowAssessment(true)}
                        disabled={!isRead && !passedStatus}
                        className="card-button"
                    >
                        Take Assessment
                    </button>
                </div>
                <img src="/assets/images/assessment.png" alt="assessment icon" className="sessioncard-image" />
              </div>
            </div>
            {passedStatus && 
          <PDFDownloadLink document={<MyPDF fullName={fullName} isComplete={isLastSession} courseTitle={courseTitle}/>} fileName="participation_certificate.pdf">
              {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button className='course-button' style={{marginBottom:"2rem"}}>Download {certificate_text} Certificate</button>)}
          </PDFDownloadLink>}
          </div>
        </>
      )}
      {showAssessment && (
        <Assessment session={session} onComplete={handleSessionComplete} completed={completed} readingMaterial={readingMaterial} isLastSession={isLastSession} courseTitle={courseTitle}/>
      )}
    </div>
  );
};

export default Session;
