// src/components/Footer.js
import React from 'react';
import './Footer.css'; // Make sure to create a CSS file for styling

const Footer = () => {
  return (
    <footer>
      <p>Copyright @ Nepal Open University 2024</p>
    </footer>
  );
};

export default Footer;
