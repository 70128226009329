import React, { useState } from 'react';
import { PUBLIC_URL } from '../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false); 

  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    setLoading(true)

    try {
      const response = await axios.post(`${PUBLIC_URL}/api/users/forgot-password`, { email });
      alert('Check your email or spam folder for the password reset link.');
        navigate('/reset-password')
    } catch (error) {
      setError(error.response?.data?.message || 'Error sending password reset link.');
    } finally {
        setLoading(false); // Set loading to false after login process
      }
  };

  return (
    <div className="login-form" style={{padding:"10px"}}>
    <form onSubmit={handleSubmit}>
    <div className='form-group' style={{margin:"50px 0px 80px 0px"}}>
        <label>Email Address:</label>
        <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{margin:"0px 0px 20px 0px"}}
            required
        />
        <button type="submit" className='btn' style={{width:"20%", float:"left"}} disabled={loading}>{loading ? 'Sending...' : 'Send Reset Link'}</button>
        <br/>
        {error && <p style={{ color: 'red', marginLeft:"20px" }}>{error}</p>}
        {message && <p style={{ color: 'green', marginLeft:"20px" }}>{message}</p>}
      </div>
    </form>
    </div>
  );
};

export default ForgotPassword;
