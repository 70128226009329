// src/components/CourseDetails.js
import React, { useContext, useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import './MainStyles.css';
import { UserContext } from './UserContext';
import axios from 'axios';
import { PUBLIC_URL } from '../config';
const CourseDetails = () => {
    const navigate = useNavigate()
    const [course, setCourse] = useState({});
    const [courseImage, setCourseImage] = useState("");

    const { id } = useParams();
    
    const handleDelete = async (e) =>{
        const confirmDelete = window.confirm("Are you sure you want to delete this course?");

        if (confirmDelete){
            try {
                const token = localStorage.getItem('token');
                const response = await axios.delete(`${PUBLIC_URL}/api/courses/delete/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }})
                if (response.status === 200) {
                alert('Course deleted successfully');
                navigate('/')
                }
            } catch (error) {
                console.error('Error deleting course:', error);
                alert('Failed to delete the course');
                }
            }
        }
    useEffect(() => {
      const fetchCourse = async () => {
        try {
          const response = await axios.get(`${PUBLIC_URL}/api/courses/${id}`);
          console.log(response.data);
          setCourse(response.data);
          const file = await axios.get(`${PUBLIC_URL}/api/courses/files/${response.data.course_image}`, {
            responseType: 'blob' 
          })
          const url = URL.createObjectURL(new Blob([file.data], { type: file.headers['content-type'] }));
          setCourseImage(url);
            
        } catch (error) {
            console.error("Error fetching course:", error);
        }
      };
  
      fetchCourse();
    }, [id]);

  const {user} = useContext(UserContext)

  if (!course) {
    return <div>Course not found</div>;
  } else{

  return (
    <div className="course-details">
        <div>
            <Link to="/" className='top-link'>
            Faculty of Social Sciences and Education
            </Link> 
            <span>   {'>'}  </span> 
            <Link to={`/course-details/${course._id}`} className='top-link active'>
            {course.title}
            </Link>
        </div>
      <h1>{course.title}</h1>
        <Link to={`/course-content-details/${course._id}`}>
            <button className="course-button">{user?"Coursework Entry":" Course Enrollment"}</button>
        </Link>
        {user&&
        <>
        <Link to={`/edit-course-content-details/${course._id}`}>
            {user.role === "admin" && <button className="course-button">Edit Course Details</button>}
        </Link>
        {user.role === "admin" &&
        <button className="card-button delete" onClick={() => handleDelete(course._id)}>
        Delete Course
        </button>}
      </>}
    
      <hr/>
      {/* <h3>
        Name of the course: {course.title} <br/>
        Course code: {course.course_metadata.course_code} <br/>
        Credit: {course.course_metadata.credit} <br/>
        Semester: {course.course_metadata.semester} <br/>
        Teaching hours: {course.course_metadata.teaching_hours} <br/>
        Nature of the course: {course.course_metadata.course_nature}</h3>
        <hr/> */}

    {/* Introduction */}
    <div>
        <div style={{display:"flex"}}>
        <div>
      <h2>Introduction</h2>
      <p dangerouslySetInnerHTML={{ __html: course.introduction }} style={{width:"90%"}}/>
        </div>
    <div className="course-image">
    {console.log(courseImage)}
            <img src="/assets/images/Guidance_and_Counseling_in_School.png" alt="Book Cover" width="300px"/>
        </div>
        </div>
      <h2>Course Enrollment and Eligibility</h2>
      <p>{course.enrollment_eligibility}</p>
    <h2>Learning Outcomes of the G &amp; C Course</h2>
        {/* School teachers are expected to demonstrate the following. They will be able to: */}
        <ul>
        {(course.course_outcomes)!==undefined && course.course_outcomes.map(outcome => (
            <li>{outcome}</li>
        ))}
        </ul>
        <h2>Course Contents</h2>
        <ul>
            {course.course_contents!==undefined && course.course_contents.map(content => (
                <li>{content}</li>
            ))}
        </ul> 
        <h2>Assessment</h2>
        <p dangerouslySetInnerHTML={{ __html: course.assessment }}/>
    </div>
    </div>
  );
}
};

export default CourseDetails;
