// src/App.js
import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import Navbar from './components/partials/Navbar';
import LoginPage from './components/Login';
import Footer from './components/partials/Footer';
import Container from './components/partials/Container';
import Courses from './components/Homepage';
import CourseDetails from './components/CourseDetails';
import Course from './components/Course';
import Register from './components/Register';
import { UserContext } from './components/UserContext';
import axios from 'axios';
import './styles.css';
import EditCourseDetails from './components/add_edit_forms/EditCourseDetails';
import AddCourseDetails from './components/add_edit_forms/AddCourseDetails';
import AddSessions from './components/add_edit_forms/AddSession';
import EditSession from './components/add_edit_forms/EditSession';
import { PUBLIC_URL } from './config';
import UserReport from './components/UserReport';
import ForgotPassword from './components/ForgetPassword';
import ResetPassword from './components/ResetPassword';

const AppContent = () => {
  const location = useLocation();
  const { user, setUser, loading } = useContext(UserContext);

  const isAuthRoute = location.pathname === '/login' || location.pathname === '/register';
  const isAuthenticated = Boolean(user);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const fetchUserData = async () => {
        try {
          const userResponse = await axios.get(`${PUBLIC_URL}/api/users/auth`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          setUser(userResponse.data);
        } catch (err) {
          console.error('Failed to fetch user data', err);
          localStorage.removeItem('token');
        }
      };
      fetchUserData();
    }
  }, [setUser]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      {!isAuthRoute && <Navbar />}
      <Container>
        <Routes>
          <Route path="/" element= {<Courses/>}/>
          <Route path="/course-details/:id" element={<CourseDetails />}/>
          <Route path="/course-content-details/:id" element={isAuthenticated ? <Course /> : <Navigate to="/login" />} />
          <Route path="/add-course-content-details" element={isAuthenticated && user?.role === "admin" ? <AddCourseDetails /> : <Navigate to="/login" />} />
          <Route path="/add-session/:course_id" element={isAuthenticated && user?.role === "admin" ? <AddSessions /> : <Navigate to="/login" />} />
          <Route path="/edit-session/:course_id/:session_id" element={isAuthenticated && user?.role === "admin" ? <EditSession /> : <Navigate to="/login" />} />
          <Route path="/edit-course-content-details/:id" element={isAuthenticated && user?.role === "admin" ? <EditCourseDetails /> : <Navigate to="/login" />} />
          <Route path="/get-course-report/:id" element={isAuthenticated && user?.role === "admin" ? <UserReport /> : <Navigate to="/login" />} />
          <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <LoginPage />} />
          <Route path="/register" element={isAuthenticated ? <Navigate to="/" /> : <Register />} />
          <Route path="/forgot-password" element={isAuthenticated ? <Navigate to="/" /> : <ForgotPassword />} />
          <Route path="/reset-password" element={isAuthenticated ? <Navigate to="/" /> : <ResetPassword />} />
        </Routes>
      </Container>
      {!isAuthRoute && <Footer />}
    </div>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedUser = localStorage.getItem('user');
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      <Router>
        <Routes>
          <Route path="/*" element={<AppContent />} />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default App;
