// src/components/Card.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './Card.css';
import { PUBLIC_URL } from '../config';


const Card = ({ course, handleCourseEnrollment, user}) => {
  const [courseImage, setCourseImage] = useState("");
  const [showVideo, setShowVideo] = useState(false);
  useEffect(() => {
    const fetchCourse = async () => {
        try {
            const file = await axios.get(`${PUBLIC_URL}/api/courses/files/${course.course_image}`, {
              responseType: 'blob' 
            })
            const url = URL.createObjectURL(new Blob([file.data], { type: file.headers['content-type'] }));
            setCourseImage(url);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    fetchCourse();
});

  return (
    <section class="content">
            <div class="text-content">
                <h1>{course.title}</h1>
                <p>21st CS, Nepal, Faculty of Social Sciences and Education, Nepal Open University</p>
                {showVideo && 
                  (
                    <div className="video-container">
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/dePzVtgU9hM"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title='MOOC Introduction Video'
                      ></iframe>
                    </div>
                  )}
                <div class="homepage-buttons">
                    <button class="intro-video" onClick={() => setShowVideo(!showVideo)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M2 4.4934C2 3.94476 2.45531 3.5 2.9918 3.5H21.0082C21.556 3.5 22 3.94495 22 4.4934V20.5066C22 21.0552 21.5447 21.5 21.0082 21.5H2.9918C2.44405 21.5 2 21.0551 2 20.5066V4.4934ZM10.6219 8.91459C10.5562 8.87078 10.479 8.84741 10.4 8.84741C10.1791 8.84741 10 9.02649 10 9.24741V15.7526C10 15.8316 10.0234 15.9088 10.0672 15.9745C10.1897 16.1583 10.4381 16.208 10.6219 16.0854L15.5008 12.8328C15.5447 12.8035 15.5824 12.7658 15.6117 12.7219C15.7343 12.5381 15.6846 12.2897 15.5008 12.1672L10.6219 8.91459Z" fill="#F5F5F5"/>
                      </svg>{showVideo?"Hide Announcement":"Announcement"}
                    </button>
                    <button class="course-intro"> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <rect width="24" height="24" transform="translate(0 0.5)" fill="white"/>
                        <path d="M21 21.5H13V6.5C13 4.84315 14.3431 3.5 16 3.5H21C21.5523 3.5 22 3.94772 22 4.5V20.5C22 21.0523 21.5523 21.5 21 21.5ZM11 21.5H3C2.44772 21.5 2 21.0523 2 20.5V4.5C2 3.94772 2.44772 3.5 3 3.5H8C9.65685 3.5 11 4.84315 11 6.5V21.5ZM11 21.5H13V23.5H11V21.5Z" fill="#0147AB"/>
                      </svg><Link to={`/course-details/${course._id}`} style={{textDecoration:"none", color:"#0147AB"}}>
                      Course Introduction</Link></button>
                </div>
                <div class="homepage-buttons">
                  <button class="sessions-assignments"  onClick={()=>handleCourseEnrollment(course._id)}> 
                      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                        <rect width="24" height="24" transform="translate(0.5 0.5)" fill="white"/>
                        <path d="M2.5 4.4934C2.5 3.94476 2.95531 3.5 3.4918 3.5H21.5082C22.056 3.5 22.5 3.94495 22.5 4.4934V20.5066C22.5 21.0552 22.0447 21.5 21.5082 21.5H3.4918C2.94405 21.5 2.5 21.0551 2.5 20.5066V4.4934ZM12.5 5.5V19.5H20.5V5.5H12.5ZM13.5 7.5H19.5V9.5H13.5V7.5ZM13.5 10.5H19.5V12.5H13.5V10.5Z" fill="#0147AB"/>
                      </svg>{user?"Coursework Entry":" Course Enrollment"}
                  </button>
                </div>
                {user && user.role === "admin" &&
                <>
                <hr style={{marginTop:"40px"}}/>
                <button className='course-button' style={{backgroundColor: 'green'}}>
                  <Link to={`/get-course-report/${course._id}`} style={{textDecoration:"none", color:"white"}}>
                    Get Course Report
                  </Link>
                </button>
                </>
              }
              {user &&
                <Link to={`/add-course-content-details`}>
                  {user.role === "admin" && <button className="course-button">Add New Course</button>}
                </Link>
              }
              </div>
            <div class="image-content">
              {/* {console.log(courseImage)} */}
              {/* courseImage ko shape namilera narakheko */}
                <img src="/assets/images/Guidance_and_Counseling_in_School.png" alt="Book Cover"/>
            </div>
        </section>
  );
};

export default Card;
