// src/components/Login.js
import React, { useContext, useState } from 'react';
import './Login.css';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import { PUBLIC_URL } from '../config';
const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); 
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      const response = await axios.post(`${PUBLIC_URL}/api/users/login`, { email, password });
      const token = response.data.token;
      // Save the token in localStorage
      localStorage.setItem('token', token);
      // Fetch user details after login
      const userResponse = await axios.get(`${PUBLIC_URL}/api/users/auth`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(userResponse.data);
      setUser(userResponse.data);
      navigate('/');
    } catch (err) {
      localStorage.removeItem('token');
      // Check if error response exists and extract the message
      if (err.response && err.response.data && err.response.data.msg) {
        setError(err.response.data.msg);
      } else {
          setError('Login failed');
      }
    } finally {
      setLoading(false); // Set loading to false after login process
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Login</h1>
        <img src="/assets/images/logo.jpeg" alt="Login" className="login-image" />
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="text"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className="error">{error}</p>}
          <p className='login-text'>Forgot Password? <Link to="/forgot-password">Reset Password</Link></p>
          <button type="submit" disabled={loading} className='btn'>
            {loading ? 'Logging in...' : 'Login'}
          </button>
          <p className='login-text'>Don't have an account? <Link to="/register">Create new account</Link></p>
        </form>
      </div>
    </div>
  );
};

export default Login;
