import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PUBLIC_URL } from '../config';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [token, setToken] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${PUBLIC_URL}/api/users/reset-password`, { token, password });
      alert('Password reset successful!');
      navigate('/login');
    } catch (error) {
      setError(error.response?.data?.message || 'Error resetting password.');
    }
  };

  return (
    <div className="login-form" style={{padding:"10px"}}>
    <form onSubmit={handleSubmit}>
    <div className='form-group' style={{margin:"50px 0px 80px 0px"}}>
    <label>Token from Email:</label>
      <input
        type="text"
        value={token}
        onChange={(e) => setToken(e.target.value)}
        required
      />
      <label>New Password:</label>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <label>Confirm New Password:</label>
      <input
        type="password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
      />
      <button type="submit" className='btn'>Reset Password</button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </form>
    </div>
  );
};

export default ResetPassword;
