import React from 'react';
import { Document, Page, Text, Image} from '@react-pdf/renderer';
// import { UserContext } from './UserContext';

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'column',
//     backgroundColor: '#fff',
//     padding:70,
//     paddingTop: 50,
//     textAlign: 'center',
//     border: '25px solid #25a2dc'
//   },
//   logo: {
//     marginBottom: 20,
//     alignSelf: 'center',
//     width: 100,
//     height: 100,
//   },
//   title: {
//     fontSize: 28,
//     marginBottom: 40,
//     marginTop: 5,
//     fontWeight: 500
//   },
//   miniTitle: {
//     fontSize: 18,
//     fontWeight: 'bold',
//     margin: 3
//   },
//   text: {
//     fontSize: 12,
//     margin: 12,
//     alignSelf: 'center',
//     fontWeight:"bold"
//   },
//   date: {
//     fontSize: 16,
//     marginTop: 50,
//     alignSelf: 'center',
//     fontWeight: 'extrabold'
//   },
// });

const MyPDF = ({fullName, isComplete, courseTitle}) => {
  // const {user} = useContext(UserContext)
  const today = new Date()
  const month = today.toLocaleString('default', { month: 'long' });
  const year = today.getFullYear();
  const date = today.getDate();
  const currentDate = date + " " + month + ", " + year;
  const nameLen = fullName.length
  const leftPosition = (55 - nameLen)+"%"

  return (
    <Document>
    <Page page='A4' style={{ position: 'relative' }} orientation='landscape'>
      <Text style={{ position: 'absolute', top: '44%', left:leftPosition }}>{fullName}</Text>
      <Text style={{ position: 'absolute', top: '56%', left:"33%" }}>{courseTitle}</Text>
      <Text style={{ position: 'absolute', bottom: '7%', left:"46%" }}>{currentDate}</Text>
      {isComplete?<Image src="/assets/images/certificate_completion.png" style={{ position: 'absolute', zIndex: -1, top: 0, width: '100%', height:'100%' }} />:<Image src="/assets/images/certificate_participation.png" style={{ position: 'absolute', zIndex: -1, top: 0, width: '100%', height:'100%' }} />}
      
    </Page>
  </Document>
  );
};

export default MyPDF;