// src/components/HomePage.js
import React, { useContext } from 'react';
import './MainStyles.css';
import Card from './Card';
import { UserContext } from './UserContext';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { PUBLIC_URL } from '../config';

const Homepage = () => {
  const { user } = useContext(UserContext)
  const [courses, setCourses] = useState([]);

  var mainPadding = "60px 40px"
  if (user && user.role === "admin"){
    mainPadding = "20px 40px"
  }
  const navigate = useNavigate();

  const handleCourseEnrollment = async (course_id) => {
    const token = localStorage.getItem('token');
    if (user) 
    {
      if (user.role === "admin") {
        navigate('/course-content-details/' + course_id);
      }
      else {
        console.log(user._id);
        try {

          const response = await axios.post(`${PUBLIC_URL}/api/courses/${course_id}/enroll`, { userId: user._id },
            {
              headers: {
                Authorization: `Bearer ${token}`
              }
            });
          console.log("User details", user)
          console.log(response.data);
          navigate('/course-content-details/' + course_id);
        } catch (error) {
          console.error(error);
        }
      }
    }
    else{
      navigate('/login')
    }
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(`${PUBLIC_URL}/api/courses`);
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  return (
    <>
      <main style={{padding: mainPadding, display:"flex", flexDirection: 'column'}}>
      {courses.map((course, index) => (
        <Card
          key={course._id}
          course={course}
          handleCourseEnrollment={handleCourseEnrollment}
          user={user}
        />
      ))}
      </main>
      </>
  );
};

export default Homepage;
