import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams} from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  Box,
  IconButton,
} from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import RemoveCircleOutlineSharpIcon from '@mui/icons-material/RemoveCircleOutlineSharp';
import { PUBLIC_URL } from '../../config';
const EditCourseDetails = () => {
  const navigate = useNavigate()
  const [course, setCourse] = useState({
    course_metadata: {
        course_code: "",
        credit: "",
        semester: "",
        teaching_hours: "",
        course_nature: ""
    },
    _id: "",
    title: "",
    introduction: "",
    enrollment_eligibility: "",
    course_outcomes: [],
    course_contents: [],
    assessment: "",
    course_image: "",
    sessions: [],
    __v: 0
});

const { id } = useParams();
const [imageUrl, setImageUrl] = useState('');

useEffect(() => {
    const fetchCourse = async () => {
        try {
            const response = await axios.get(`${PUBLIC_URL}/api/courses/${id}`);
            let filename = response.data.course_image;
            const file = await axios.get(`${PUBLIC_URL}/api/courses/files/${filename}`, {
              responseType: 'blob' 
            })
            const url = URL.createObjectURL(new Blob([file.data], { type: file.headers['content-type'] }));
            console.log(url)
            setCourse(response.data);
            setImageUrl(url);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    fetchCourse();
}, [id]);

useEffect(() => {
    setCourseId(course._id);
    setCourseTitle(course.title);
    setCourseImage(course.course_image);
    setCourseMetadata({
        courseCode: course.course_metadata.course_code,
        credit: course.course_metadata.credit,
        semester: course.course_metadata.semester,
        teachingHours: course.course_metadata.teaching_hours,
        courseNature: course.course_metadata.course_nature,
    });
    setCourseIntroduction(course.introduction);
    setEnrollmentEligibility(course.enrollment_eligibility);
    setCourseOutcomes(course.course_outcomes);
    setCourseContents(course.course_contents);
    setAssessment(course.assessment);
}, [course]);

const [courseId, setCourseId] = useState(course._id);
console.log(courseId)
const [courseTitle, setCourseTitle] = useState(course.title);
const [courseImage, setCourseImage] = useState(course.course_image);
const [courseMetadata, setCourseMetadata] = useState({
    courseCode: course.course_metadata.course_code,
    credit: course.course_metadata.credit,
    semester: course.course_metadata.semester,
    teachingHours: course.course_metadata.teaching_hours,
    courseNature: course.course_metadata.course_nature,
});
const [courseIntroduction, setCourseIntroduction] = useState(course.introduction);
const [enrollmentEligibility, setEnrollmentEligibility] = useState(course.enrollment_eligibility);
const [courseOutcomes, setCourseOutcomes] = useState(course.course_outcomes);
const [courseContents, setCourseContents] = useState(course.course_contents);
const [assessment, setAssessment] = useState(course.assessment);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setCourseImage(e.target.files[0]);
      setImageUrl(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleAddOutcome = () => {
    setCourseOutcomes([...courseOutcomes, '']);
  };

  const handlePopOutcome = () => {
    setCourseOutcomes(courseOutcomes.slice(0, -1));
  };

  const handleOutcomeChange = (index, value) => {
    const newOutcomes = [...courseOutcomes];
    newOutcomes[index] = value;
    setCourseOutcomes(newOutcomes);
  };

  const handleAddContent = () => {
    setCourseContents([...courseContents, '']);
  };

  const handlePopContent = () => {
    setCourseContents(courseContents.slice(0, -1));
  };

  const handleContentChange = (index, value) => {
    const newContents = [...courseContents];
    newContents[index] = value;
    setCourseContents(newContents);
  };

  const handleSubmit = async (e) => {
    console.log("helloo")
    e.preventDefault();
    // Database ma data pathaune
    try {
      const formData = new FormData();
      formData.append('title', courseTitle);
      formData.append('introduction', courseIntroduction);
      formData.append('enrollment_eligibility', enrollmentEligibility);
      const updatedOutcomes = courseOutcomes.map(outcome => {
        if (!outcome.endsWith(';')) {
          return outcome + ';';
        }
        return outcome;
      });
      formData.append('course_outcomes', updatedOutcomes);
      const updatedContents = courseContents.map(content => {
        if (!content.endsWith(';')) {
          return content + ';';
        }
        return content;
      });
      formData.append('course_contents', updatedContents);
      formData.append('assessment', assessment);
      formData.append('course_image', courseImage);
      formData.append('course_metadata', JSON.stringify(courseMetadata));
      let token = localStorage.getItem('token')
      await axios.post(`${PUBLIC_URL}/api/courses/edit/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`
        }
      }); 
      alert('Course introduction updated successfully');
      navigate(`/course-details/${id}`)
    } catch (error) {
      alert('Failed to update course introduction');
    }
    
  };
  return (
    <Container maxWidth="md">
      <Box mt={4}>
        <h1>Edit Course Details</h1>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Course Title"
                fullWidth
                style={{marginTop: "5px"}}
                variant="outlined"
                value={courseTitle}
                onChange={(e) => setCourseTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="course-image"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="course-image">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  sx={{ backgroundColor: '#0147AB' }}
                >
                  Upload New Course Image
                </Button>
              </label>
              {courseImage && (
                <Box mt={2} display="flex" alignItems="center">
                  <img
                  src={imageUrl}                   alt="Course"
                    style={{ width: '100px', height: '100px', marginRight: '10px' }}
                  />
                  <Typography variant="body2">{courseImage.name}</Typography>
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Course Introduction"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={courseIntroduction}
                onChange={(e) => setCourseIntroduction(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Enrollment Eligibility"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={enrollmentEligibility}
                onChange={(e) => setEnrollmentEligibility(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: '20px' }}>Course Outcomes</Typography>
              {courseOutcomes.map((outcome, index) => (
                <TextField
                  key={index}
                  label={`Outcome ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  value={outcome}
                  onChange={(e) => handleOutcomeChange(index, e.target.value)}
                  style={{ marginBottom: '8px' }}
                />
              ))}
              <IconButton color="primary" onClick={handleAddOutcome}>
                <AddCircleOutline />
              </IconButton>
              <IconButton color="primary" onClick={handlePopOutcome}>
                <RemoveCircleOutlineSharpIcon/>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: '20px' }}>Course Contents</Typography>
              {courseContents.map((content, index) => (
                <TextField
                  key={index}
                  label={`Content ${index + 1}`}
                  fullWidth
                  variant="outlined"
                  value={content}
                  onChange={(e) => handleContentChange(index, e.target.value)}
                  style={{ marginBottom: '8px' }}
                />
              ))}
              <IconButton color="primary" onClick={handleAddContent}>
                <AddCircleOutline />
              </IconButton>
              <IconButton color="primary" onClick={handlePopContent}>
                <RemoveCircleOutlineSharpIcon/>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Assessment"
                fullWidth
                multiline
                rows={4}
                variant="outlined"
                value={assessment}
                onChange={(e) => setAssessment(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button variant="contained" color="primary" type="submit" sx={{ backgroundColor: '#0147AB' }}>
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default EditCourseDetails;
