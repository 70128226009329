// src/components/UserContext.js
import React, { createContext, useState } from 'react';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [courses, setCourses] = useState([]);

  return (
    <UserContext.Provider value={{ user, setUser, courses, setCourses }}>
      {children}
    </UserContext.Provider>
  );
};
